<template>
  <div>
    <router-link class="come-back" to="/admin/project">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование проекта' : 'Создание проекта' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="addProject" ref="addProject">
      <div>
        <div class="content-sb">
          <p>Название<span>*</span></p>
          <input required placeholder="Введите название проекта" type="text" name="name" v-model="form.name" class="input blue">
        </div>

        <div class="content-sb">
          <p>Дата<span>*</span></p>
          <input required  type="date" v-model="form.date" name="date" class="input blue">
        </div>

        <div class="content-sb">
          <p>Описание проекта</p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="form.description"></ckeditor>
        </div>

        <div class="content-sb">
          <p>Контент проекта</p>
          <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" v-model="form.description_1"></ckeditor>
        </div>

        <div v-if="$route.query.type === 'edit'" class="content-sb">
          <p>Старое превью</p>
          <img class="old-photo" :src="`${$store.getters.getUrlApi}${form.previewPhoto}`" alt="">
        </div>
        <div class="content-sb">
          <p>Превью<span>*</span></p>
          <input :required="$route.query.type !== 'edit'"  type="file" name="preview" ref="preview"  class="input blue">
        </div>
        <div v-for="(item, i) in documents" :key="`documents${i}`">
            <div class="content-sb">
              <p>Приоритет</p>
              <input required v-model="documents[i].order" type="number"  class="input blue">
            </div>
            <div class="content-sb">
              <p>Название файла</p>
              <input required placeholder="Ввыедите название файла" v-model="documents[i].title" type="text"  class="input blue">
            </div>
            <div class="content-sb">
              <p>Впишите теги через запятую</p>
              <tags @dataTags="dataTags($event, i)" :start-value="item.tagList"/>
            </div>
            <div v-if="$route.query.type === 'edit' && item.version === 'oldFile'" class="content-sb">
              <p>Старый докумет</p>
              <a target="_blank" :href="`${$store.getters.getUrlApi}${item.url}`">ссылка</a>
            </div>
            <div v-else class="content-sb">
              <p>Документы проекта<span>*</span></p>
              <input required type="file" @change="addFile($event, i)" ref="documents"  class="input blue">
            </div>
            <div class="btn-red" @click="deleteDocumentsList(i)">Удалить</div>
        </div>
      </div>
      <div class="btn-blue" @click="addDocumentsList">Добавить</div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/project" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'
export default {
  name: 'createProject',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailProject : {
        name: '',
        date: '',
        description: '',
        description_1: ''
      },
      documents: []
    }
  },
  methods: {
    addProject () {
      const formData = new FormData(this.$refs.addProject)
      formData.append('description', this.form.description)
      formData.append('description_1', this.form.description_1)
      this.documents.forEach((item, i) => {
        if (item.file) {
          formData.append(`documents[${i}][file]`, item.file)
        }
        if (this.$route.query.type === 'edit' && item.id) {
          formData.append(`documents[${i}][id]`, item.id)
        }
        formData.append(`documents[${i}][order]`, item.order)
        formData.append(`documents[${i}][title]`, item.title)
        formData.append(`documents[${i}][tagList]`, JSON.stringify(item.tagList))
      })
      // formData.append('documents', this.form.documents)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editProject', { id: this.form.id, data: formData })
      } else {
        this.$store.dispatch('AddProject', formData)
      }
    },
    dataTags (value, i) {
      console.log(value, i)
      // this.form.documents[i].tagList = value
    },
    addDocumentsList () {
      this.documents.push({
        file: null,
        order: 0,
        version: 'newFile',
        tagList: [],
        title: ''
      })
    },
    deleteDocumentsList (i) {
      this.documents.splice(i, 1)
    },
    addFile (event, i) {
      this.documents[i].file = event.target.files[0]
    }
  },
  watch: {
    '$store.getters.getDetailProject': {
      deep: true,
      handler (value) {
        if (this.documents.length === 0) {
          value.documentList.map(item => {
            this.documents.push({
              id: item.id,
              title: item.name,
              file: null,
              url: item.url,
              order: item.order,
              version: 'oldFile',
              tagList: item.tagList
            })
          })
        }
      }
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailProject === null) {
      this.$router.push('/admin/project')
    }
  },
  components: {
    TricolorLine,
    Tags
  }
}
</script>

<style lang="scss">
.btn-red, .btn-blue {
  margin-bottom: 2rem;
  display: inline-flex;
}

.old-photo{
  width: 33.75rem
}

.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
